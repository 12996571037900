import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';

import { RichText } from 'prismic-reactjs';

import LinkFragment from '../utils/LinkFragment';
import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import SliceZone from '../components/SliceZone';
import HeroCarousel from '../components/HeroCarousel';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(80)};
`;

function Page({ data, pageContext }) {
  useLangRedirect(pageContext);

  const { page } = data.prismic;

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length ? (
        <HeroCarousel items={page.hero_items} />
      ) : (
        <Hero
          heading={RichText.asText(page.title)}
          image={page.featured_imageSharp}
          pageTitle
        />
      )}

      <Container>
        <Row>
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Page.fragments = [LinkFragment];

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismic {
      page(uid: $uid, lang: $lang) {
        _meta {
          uid
        }
        title
        body {
          ... on PRISMIC_PageBodyCode_block {
            type
            label
            primary {
              codeBlock
            }
          }
          ... on PRISMIC_PageBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_PageBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        seo_description
        seo_meta_title
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_items {
          ... on PRISMIC_PageHero_itemsImage_item {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              link {
                ...LinkFragment
              }
              title
              text
              pre_title
              link_label
            }
          }
          ... on PRISMIC_PageHero_itemsVideo_item {
            type
            label
            primary {
              link {
                ...LinkFragment
              }
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              link_label
              pre_title
              text
              title
              video {
                ... on PRISMIC__FileLink {
                  _linkType
                  name
                  size
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
